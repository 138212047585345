$screen-sm: 600px;

@mixin maxlines($lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
}

.container__divider {
    &:not(:last-child) {
        border-bottom: 1px solid #eaeaea;
    }
    padding: 1em 0;
}

.container {
    padding: 1em 0;
}

.item {
    display: flex;
    align-items: center;
}

.cart__item {
    display: flex;
    justify-content: space-between;

    span {
        @include maxlines(2);
        margin-left: 8px;
    }

    p {
        text-align: right;
        white-space: nowrap;
    }
}
.quantity__selector__disabled {
    background-color: #eaeaea;
    min-width: 34px;
    text-align: center;
    padding: 0.2em 0;
}

.quantity__selector__enabled {
    background-color: #eaeaea;
}

.checkout__btn {
    @media (max-width: $screen-sm) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 8px;
    }
}

.item__count {
    position: absolute;
    left: 0;
    border: 1px solid white;
    margin-left: 16px;
    padding-left: 8px;
    padding-right: 8px;
}

.price {
    position: absolute;
    right: 0;
    margin-right: 16px;
}
