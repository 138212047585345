@import "./variables.scss";

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

hr {
  border: 1px solid #eaeaea;
}

.bg {
  background-color: #fafafa;
}

.primary {
  color: $color-primary;
}

.secondary {
  color: $color-secondary;
}

.pre-line {
  white-space: pre-line;
}

.f16 {
  font-size: 16px;
}

.text__center {
  text-align: center;
}

.font__black {
  font-weight: 900;
}

.container-lg {
  width: 100%;
  max-width: $screen-lg;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5em;
  padding-right: 1em;
}

.container {
  min-height: 60vh;
}

.color-white {
  color: white;
}

.textSecondary {
  color: #5a5a5a;
}

.white {
  background-color: white;
}

.grow {
  flex-grow: 1;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.capitalize-p {
  p {
    text-transform: capitalize;
  }
}

.bold-p {
  p {
    font-weight: bold;
  }
}

.bold-span {
  span {
    font-weight: bold;
  }
}

.noselect {
  @include noselect();
}

.error-page {
  @include center();
  text-align: center;
  flex-direction: column;
  min-height: 65vh;

  span {
    display: block;
  }
}

.nowrap {
  white-space: nowrap;
}

@mixin sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.sticky__footer {
  @include sticky-footer();
}

.one-line {
  @include maxlines(1);
}

.appbar {
  border-bottom: 1px solid #eaeaea;
}

.border {
  border: 1px solid #eaeaea;
}

.radius {
  border-radius: 4px;
}

.add__btn {
  &:button {
    background-color: #eaeaea;
    border-radius: 4;
  }
}

button {
  outline: none;
  border: none;
  cursor: pointer;

  &:active {
    background-color: lightgray;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}

.autocomplete__input {
  height: 50px;
  width: 100%;
  border-color: #eaeaea;
  border-width: 1px;
}

.action__btn {
  padding: 0.7em;

  font-weight: 600;
  border-radius: 4px;
}

.font__x__large {
  font-size: x-large;
}

.font__normal {
  span {
    font-size: medium;
  }
}

h1 {
  font-weight: 700;
  margin: 0;
  font-size: 2em;

  @media (max-width: $screen-xs) {
    font-size: 1.8em;
  }
}

h2 {
  margin: 0.5em 0 0.5em 0;
  font-weight: 900;
  font-size: 24px;

  @media (max-width: $screen-xs) {
    font-size: 20px;
  }
}

.h2-margin {
  margin-top: -0.5em;

  @media (max-width: $screen-md) {
    margin-top: 2em;
  }
}

h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 0.5em 0;
}

.relative {
  position: relative;
}

@mixin dropzone($height, $screenSmHeight) {
  position: relative;
  width: 100%;
  height: $height;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $custom-gray;
  border: 2px dotted lightgray;
  cursor: pointer;
}

.fit {
  max-width: fit-content;
}

.img {
  position: relative;
  object-fit: cover;
  background-size: cover;
  height: 90px;
  min-height: 90px;
  max-height: 90px;
  width: 90px;
  min-width: 90px;
  max-width: 90px;
}

.hero-dropzone {
  @include dropzone(300, 150);
}

.img-dropzone {
  @include dropzone(400, 200);
}

dialog {
  align-self: center;
}

.flex-auto {
  display: flex;
  @media (max-width: $screen-sm) {
    flex-direction: column;
    margin-top: 0.5em;
  }
}

img {
  object-fit: cover;
  background-size: cover;
  // max-height: 400px;
  // @media (max-width: $screen-sm) {
  //   max-height: 200px;
  // }
}

.spcard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // align-items: center;
}

.between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    text-align: left;
    white-space: nowrap;
  }

  p {
    text-align: right;
    white-space: nowrap;
  }
}

.error {
  color: red;
}

.flex {
  display: flex;
  width: 100%;
}
.flex-v {
  flex-direction: column;
}

.align-center {
  align-items: center;
  // justify-content: center;
}

.justify-flex-end {
  justify-content: flex-end;
}

.align-flex-end {
  align-items: flex-end;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.align-end-centered {
  justify-content: flex-end;
  align-items: center;
  // align-items: flex-end;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 6em;
}

.gap {
  gap: 4px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.column {
  display: flex;
  flex-direction: column;
}

.center-v {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.center-b {
  display: flex;
  height: 100%;
  // width: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.center-l {
  display: flex;
  align-items: center;
  justify-content: left;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bold {
  font-weight: bold;

  span {
    font-weight: bold;
  }

  p {
    font-weight: bold;
  }
}

.light {
  p {
    font-weight: 300;
  }

  span {
    font-weight: 300;
  }
}

.p0 {
  padding: 0;
}

.p05-smUp {
  @media (min-width: $screen-sm) {
    padding: 0.5em;
  }
}

.p1 {
  padding: 1em;
}

.p05 {
  padding: 0.5em;
}

.ph05 {
  padding: 0 0.5em;
}

.ph1 {
  padding: 0 1em;
}
.ph2 {
  padding: 0 2em;
}

.p2 {
  padding: 2em;
}

.mv05 {
  margin: 0.5em 0;
}

.mv1 {
  margin: 1em 0;
}

.pv1 {
  padding: 1em 0;
}
.pv2 {
  padding: 2em 0;
}

.pv05 {
  padding: 0.5em 0;
}

.pl1 {
  padding-left: 1em;
}

.m0 {
  margin: 0;
}

.m05 {
  margin: 0.5em;
}
.m1 {
  margin: 1em;
}

.ml05 {
  margin-left: 0.5em;
}

.mh05 {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.ml1 {
  margin-left: 1em;
}

.ml05 {
  margin-left: 0.5em;
}

.mr05 {
  margin-right: 0.5em;
}

.mr1 {
  margin-right: 1em;
}

.mr2 {
  margin-right: 2em;
}

.mh05 {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.mh1 {
  margin-left: 1em;
  margin-right: 1em;
}

.mt05 {
  margin-top: 0.5em;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: 1em;
}

.mt2 {
  margin-top: 2em;
}
.mt3 {
  margin-top: 2em;
}

.mb05 {
  margin-bottom: 0.5em;
}

.mb1 {
  margin-bottom: 1em;
}

.mb2 {
  margin-bottom: 2em;
}

.pb1 {
  padding-bottom: 1em;
}

.pt-05 {
  padding-top: 0.5em;
}

.pb-05 {
  padding-bottom: 0.5em;
}

.pt1 {
  padding-top: 1em;
}

.pt2 {
  padding-top: 2em;
}

.li-center {
  text-align: center;
}

.maps {
  width: 100%;
  overflow: hidden;
  height: 300px;

  iframe {
    margin-top: -150px;
  }
}

.category-divider {
  padding-bottom: 2em;
}

.category-divider:not(:last-child) {
  // padding-bottom: 2em;
  border-bottom: 1em solid $custom-gray;
}

.section__separator {
  margin-bottom: 1em;
  @media (max-width: $screen-sm) {
    // margin-left: -1em;
    // margin-right: -1em;
    border: 1em solid #eaeaea;
  }
}

.horizontal__scroll {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  white-space: nowrap;
}

.m0 {
  margin: 0;
}

.separator:not(:last-child) {
  border-bottom: 1px solid #eaeaea;
}

.divider:not(:last-child) {
  @media (max-width: $screen-md) {
    border-bottom: 1px solid $custom-gray;
  }
}

.hover {
  &:hover {
    cursor: pointer;
  }
}

.card {
  @include noselect();
  display: flex;
  justify-content: space-between;
  padding: 0.5em;

  @media (max-width: $screen-md) {
    border-bottom: 1px solid $custom-gray;
  }

  @media (min-width: $screen-md) {
    //large screen
    padding: 1em;
    border: 0.2px solid #eaeaea;
    border-radius: 4px;

    &:hover {
      border: 0.2px solid $color-primary;
    }
  }

  span {
    font-weight: 400;
  }

  p {
    @include maxlines(2);
  }

  &:hover {
    cursor: pointer;
  }
}

.native__select {
  padding: 0.5em;
  background-color: #eaeaea;
}

.pwa__gap {
  @media (max-width: $screen-sm) {
    padding-top: 5em;
    background-color: white;
  }
}

.cart__checkout__gap {
  @media (max-width: $screen-sm) {
    padding-bottom: 8em;
  }
}

.cart__btn {
  // z-index: 3;
  @media (max-width: $screen-sm) {
    position: fixed;
    left: 8px;
    right: 8px;
    bottom: 24px;
  }

  .item__count {
    position: absolute;
    left: 0;
    border: 1px solid white;
    background-color: #2d2c2d;
    border-radius: 4px;
    margin-left: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .price {
    position: absolute;
    right: 0;
    margin-right: 16px;
  }
}

.admin-tf {
  margin-top: 0.5em;
  margin-bottom: 1em;
  padding: 0.3em 0;
}

.admin-tf:nth-of-type(odd) {
  // background-color: #eaeaea;
  border-radius: 8px;
}

.blurhash {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

// .large__divider:not(:last-child) {
//    margin: 1em -1em;
//    border-bottom: 1em solid whitesmoke;
// }

.large__divider__bottom {
  margin: 0 -1em;
  // border-bottom: 1em solid whitesmoke;
}

.order-summary-item {
  text-align: center;
  font-weight: 700;

  p {
    font-weight: 900;
    color: $color-error;
  }

  span {
    font-weight: 400;
  }
}

.sticky-header {
  position: sticky;
  position: -webkit-sticky;
  // top: 4.3em;
}

.rounded-corners {
  border-radius: 8px;
}
