.footer {
    border-top: 1px solid #eaeaea;
    height: auto;
    padding: 3em 0;
    background-color: #fafafa;

    span {
        color: #e60017;
    }

    a {
        margin-bottom: 1em;
        color: black;
    }

    h3 {
        font-weight: 900;
    }
}
