$screen-sm: 600px;

@mixin between {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex: 1;
    span {
        // @include maxlines(1);
        flex: 0.7;
        text-align: left;
    }
    p {
        flex: 0.3;
        margin-left: 2em;
        text-align: right;
        white-space: nowrap;
    }
}

.total__text {
    @include between();

    span {
        font-size: larger;
        font-weight: 700;
    }
    p {
        font-size: larger;
        font-weight: 700;
        color: black;
    }
}

.page__checkout__btn {
    @media (max-width: $screen-sm) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 8px;
    }

    .item__count {
        position: absolute;
        left: 0;
        border: 1px solid white;
        margin-left: 16px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .price {
        position: absolute;
        right: 0;
        margin-right: 16px;
    }
}

.list__item {
    display: flex;
    align-items: center;

    p {
        margin: 0;
        margin-left: 1em;
    }
    span {
        margin: 0;
    }
}

.payment__card__container {
    border: 1px solid #bcbcbc;
    padding: 1em;
    border-radius: 4px;
}

.map__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    margin-top: 1em;
}

.map__delivery__type__options {
    display: flex;
    width: fit-content;
    border-radius: 16px;
    align-items: center;
    margin-bottom: -48px;
    margin-left: 16px;
    z-index: 2;
    background-color: white;
}

.add__card__selected {
    border: 1px solid #e0e0e0;
    border-top-color: transparent;
    // border-radius: 4px;
}
