@import "../../styles/variables";

.container {
	position: relative;
	display: flex;
	justify-content: center;
	border: 4px solid #ebebeb;
	border-radius: 999px;
	@media (max-width: $screen-sm) {
		width: 100%;
	}

	@media (min-width: $screen-sm) {
		max-width: 375px;
	}

	background-color: #ebebeb;
}

.selector {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 999px;
	transition: all ease-in 0.2s;
	z-index: 1;
}

.selectorPrimary {
	background-color: white;
}
.selectorSecondary {
	background-color: white;
}
