.appbar {
    border-bottom: 1px solid #eaeaea;
}

.title {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
}

.logo {
    border-radius: 4px;
}
