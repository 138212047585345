$screen-sm: 600px;
$screen-lg: 1280px;

.tab__container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: sticky;
    position: -webkit-sticky;
    top: 4.92em;
    @media (max-width: $screen-sm) {
        top: 3.92em;
    }
    border-bottom: 1px solid #eaeaea;
    background-color: white;
}

.tabs {
    button {
        white-space: nowrap;
    }
}

.placeholder {
    height: 49px;
    border-bottom: 1px solid #eaeaea;
    background-color: white;
}
