@import "../../styles/variables.scss";

$border-radius: 4px;
$border-color-hover: #727272;

.product__category:not(:last-child) {
  padding-bottom: 2em;
  border-bottom: 1em solid $custom-gray;
}

.product__divider:not(:last-child) {
  @media (max-width: $screen-md) {
    border-bottom: 1px solid $custom-gray;
  }
}

  @mixin product {
  @include noselect();
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
  width: 100%;

  img {
    border-radius: $border-radius;
  }

  @media (min-width: $screen-md) {
    //large screen
    padding: 1em;
    border: 0.2px solid $custom-gray;
    border-radius: $border-radius;

    &:hover {
      border: 0.2px solid $border-color-hover;
    }
  }

  .title {
    p,
    h1,
    h3 {
      font-size: large;
      font-weight: 700;
      margin: 0;
    }

    span {
      margin: 8px 0;
      @include maxlines(2);
      color: $border-color-hover;
    }

    small {
      color: $color-error;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.product {
  @include product();
}

.product__in__cart {
  @include product();
  @media (min-width: $screen-md) {
    border: 0.2px solid $color-primary;
  }
}

.img__small {
  // min-width: 80px;
  // min-height: 80px;
  // width: 80px;
  // height: 80px;

  border: 1px solid $custom-gray;
  // span {
  //     position: fixed;
  //     right: -8;
  //     background-color: $color-primary;
  //     color: white;
  // }
}

.img__product__count {
  position: absolute;
  width: 2em;
  height: 1.5em;
  text-align: center;
  border-top-left-radius: 4px;
  color: white;
  background-color: $color-primary;
}

.img__small {
  position: relative;
  object-fit: cover;
  background-size: cover;
  height: 90px;
  min-height: 90px;
  max-height: 90px;
  width: 90px;
  min-width: 90px;
  max-width: 90px;
}

.img__large {
  object-fit: cover;
  background-size: cover;
  background-color: $custom-gray;
  height: 375px;
  width: 100%;
  @media (max-width: $screen-xs) {
    height: 210px;
  }
}
